<template>
  <div class="mb-16">
    <h2 class='pb-4 md:pb-8 text-cdlpink'>Partner With Us</h2>

    <div class="grid md:grid-cols-2 gap-4">
      <BaseHeadingAndText
        heading="I am “Shelter”"
        headingSize="2xl"
        class="bg-cdlpeach-dark rounded-2xl p-4"
      >
        <p>
          Are your organization found upon neverending love for animals? Then we are waiting to support your efforts!
        </p>
        <BaseButton class="mt-4" @click="onGetCuddled">Get cuddled</BaseButton>
      </BaseHeadingAndText>

      <BaseHeadingAndText
        heading="I am a “socially responsible company”"
        headingSize="2xl"
        class="bg-cdlpeach-dark rounded-2xl p-4"
      >
        <p>
          We are looking for same minded companies, as well as a socially responsible crypto world. Get on board in the next fundraising revolution with us!
        </p>
        <BaseButton class="mt-4" @click="onGiveCuddles">Give cuddles</BaseButton>
      </BaseHeadingAndText>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImpactFundApply",
  components: {},
  data() {
    return {
    }
  },
  methods: {
    onGetCuddled() {
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSeJkht1dIa9m1VrP_0gR_LI6vcN-5hRbORTsTJUWia7feCHqQ/viewform', '_blank')
    },
    onGiveCuddles() {
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSf0FAcmIToP7UQey111X2bjtGsUsJrBmCzzUKp98qv4IYQtfA/viewform', '_blank')
    }
  },
}
</script>
