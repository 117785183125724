<template>
  <div>
    <h2 class='text-cdlpink pb-10'>How the funds flow</h2>

    <div
      class="mt-12 grid grid-cols-3 gap-0 items-end justify-center text-center text-xl font-bold"
    >
      <img class="mx-auto" :src="iconCompany" />
      <img class="mx-auto" :src="iconJoe" />
      <img class="mx-auto" :src="iconCryptohead" />
      <span class="pt-2">Company</span>
      <span class="pt-2">Joe</span>
      <span class="pt-2">Cryptohead</span>

      <img class="mx-auto py-8" :src="arrowTlBr" />
      <img class="mx-auto py-8" :src="arrowTB" />
      <img class="mx-auto py-8" :src="arrowTrBl" />

      <div class='bg-cdlcream  p-2 pb-0 flex justify-center items-end h-full rounded-tl-lg'><img class="mx-auto" :src="iconMerch" /></div>
      <div class='bg-cdlcream  p-2 pb-0 flex justify-center items-end h-full'><img class="mx-auto" :src="iconNft" /></div>
      <div class='bg-cdlcream  p-2 pb-0 flex justify-center items-end h-full rounded-tr-lg'><img class="mx-auto" :src="iconWidget" /></div>
      <span class="bg-cdlcream pt-2 rounded-bl-lg">Merch</span>
      <span class="bg-cdlcream pt-2">Future NFT projects (TBA)</span>
      <span class="bg-cdlcream pt-2 rounded-br-lg">Widgets</span>

      <div></div>
      <img class="mx-auto py-8" :src="arrowTB2" />
      <div></div>

      <div></div>
      <img class="mx-auto" :src="iconFund" />

      <div></div>
      <div></div>
      <span class="pt-2">Impact Fund</span>
      <div></div>

      <img class="mx-auto py-8" :src="arrowTrBl" />
      <img class="mx-auto py-8" :src="arrowTB" />
      <img class="mx-auto py-8" :src="arrowTlBr" />

      <img class="mx-auto" :src="iconShelter" />
      <img class="mx-auto" :src="iconVet" />
      <img class="mx-auto" :src="iconRnd" />
      <span class="pt-2">Shelters</span>
      <span class="pt-2">Vets</span>
      <span class="pt-2">R&D</span>
    </div>

    <p class='mt-8'><b>Financial sources</b> - we receive donations from companies, crypto heads, regular Joe) – in short, our supporters.<br><br>
 
<b>Our Tools</b> – additional stuff we create or plan to create to raise our funding such as proceeds from future planned NFT collections<br><br>
 
<b>Impact Fund</b> – publicly transparent place where we deposit the proceeds/incomes/earnings/donations<br><br>
 
<b>Diversification</b> - the money collected is used in four main domains of our charity efforts (urgent cases, animals, veterinary patients, R+D companies)</p>
  </div>
</template>

<script>
import iconFund from "@/assets/icon-fund.svg?raw"
import iconNft from "@/assets/icon-nft.svg?raw"
import iconMerch from "@/assets/icon-merch.svg?raw"
import iconShelter from "@/assets/icon-shelter.svg?raw"
import iconVet from "@/assets/icon-vet.svg?raw"
import iconRnd from "@/assets/icon-rnd.svg?raw"
import iconJoe from "@/assets/icon-joe.svg?raw"
import iconCryptohead from "@/assets/icon-cryptohead.svg?raw"
import iconCompany from "@/assets/icon-company.svg?raw"
import iconWidget from "@/assets/icon-widget.svg?raw"
import arrowTB from "@/assets/arrow-t-b.svg?raw"
import arrowTB2 from "@/assets/arrow-t-b-2.svg?raw"
import arrowTlBr from "@/assets/arrow-tl-br.svg?raw"
import arrowTrBl from "@/assets/arrow-tr-bl.svg?raw"

export default {
  name: "ImpactFundJustImagine",
  components: {
    iconFund,
    iconNft,
    iconMerch,
    iconShelter,
    iconVet,
    iconRnd,
    iconJoe,
    iconCryptohead,
    iconCompany,
    iconWidget,
    arrowTB,
    arrowTB2,
    arrowTlBr,
    arrowTrBl,
  },
  data() {
    return {
      iconFund,
      iconNft,
      iconMerch,
      iconShelter,
      iconVet,
      iconRnd,
      iconJoe,
      iconCryptohead,
      iconCompany,
      iconWidget,
      arrowTB,
      arrowTB2,
      arrowTlBr,
      arrowTrBl,
    }
  },
  methods: {},
}
</script>
