<template>
  <div>
    <!-- <BaseHeadingAndText heading="Just imagine 😔">
      <ul class=''>
        <li class='flex flex-row pb-4 text-xl items-start'><ImageCheck class='w-5 h-5 flex-shrink-0 mr-4 mt-1' />50% of dogs over 10 years old develop cancer</li>
        <li class='flex flex-row pb-4 text-xl items-start'><ImageCheck class='w-5 h-5 flex-shrink-0 mr-4 mt-1' />Over 98% of pets in the US are uninsured</li>
        <li class='flex flex-row pb-4 text-xl items-start'><ImageCheck class='w-5 h-5 flex-shrink-0 mr-4 mt-1' />About 56% of dogs and 71% of cats entering shelters in the US will be euthanized</li>
      </ul>
    </BaseHeadingAndText> -->
    <div>
      <div class="mb-8" v-for="item in items" :key="item.id">
        <BaseHeadingAndText :heading="item.title" :text="item.text" />
      </div>
    </div>

    <!-- <BaseButton class="mt-4" :url="mediumImpactFund">Find out more</BaseButton> -->
  </div>
</template>

<script>
import ImageCheck from "@/assets/images/checkbox.svg?inline"

export default {
  name: "ImpactFundWhatItIs",
  components: {
    ImageCheck,
  },
  data() {
    return {
      items: [
        {
          id: "exercitation",
          title: 'What is "impact fund"',
          text: "Transparent charity fund, which is publicly accessible and auditable by anyone, making sure all of the proceeds go in the right direction and place as declared. Transparency is the key!",
        },
        {
          id: "nostrud",
          title: "Sources of funding",
          text: "All the funds are raised by the people like you, real-world companies,  and crypto minded socially responsible",
        },
        {
          id: "deserunt",
          title: "Use of funding",
          text: "All funds raised are directed toward approved and verified shelters, veterinary patients, victims of humanitarian catastrophes and independent medical research and development subjects",
        },
      ],
      // showMore: false,
      // mediumImpactFund:
      //   "https://medium.com/cuddlenft/introducing-the-impact-fund-259a0cf533c",
    }
  },
  methods: {
    onShowMore() {
      console.log("show more")
      this.showMore = true
    },
  },
}
</script>
