<template>
  <div>
    <h2 class='text-cdlpink'>OUR MISSION</h2>
    <BaseHeadingAndText>
      Mission? Real impact. True change. Goals? To help those in need. Means? We have built the project from our own private funds. Plan? To expand and extend our activities, because we leave no one behind!!!!
    </BaseHeadingAndText>

    <!-- <BaseButton class="mt-4" :url="mediumImpactFund">Find out more</BaseButton> -->
  </div>
</template>

<script>
import ImageCheck from "@/assets/images/checkbox.svg?inline"

export default {
  name: "ImpactFundWeAreHereFor",
  components: {
    ImageCheck,
  },
  data() {
    return {
      // showMore: false,
      // mediumImpactFund:
      //   "https://medium.com/cuddlenft/introducing-the-impact-fund-259a0cf533c",
    }
  },
  methods: {
    onShowMore() {
      console.log("show more")
      this.showMore = true
    },
  },
}
</script>
