<template>
  <div>
    <BaseContainer class="pt-32 pb-16 relative z-20">
      <ImpactFundHero class="" />
      <ImpactFundWhatItIs class="pt-28" />
      <ImpactFundMoneyFlow class="pt-28" />
      <ImpactFundWeAreHereFor class="pt-28" />
      <!-- <ImpactFundPartners class="pt-16" /> -->
      <!-- <ImpactFundStatistics class="pt-16" /> -->
      <ImpactFundInterested class="pt-16 md:pt-24 lg:pt-32" />
    </BaseContainer>
    <Footer />
  </div>
</template>

<script>
import ImpactFundHero from "@/components/ImpactFund/ImpactFundHero"
import Footer from "@/components/common/Footer"
import ImpactFundWhatItIs from "../components/ImpactFund/ImpactFundWhatItIs.vue"
import ImpactFundMoneyFlow from "../components/ImpactFund/ImpactFundMoneyFlow.vue"
import ImpactFundWeAreHereFor from "../components/ImpactFund/ImpactFundWeAreHereFor.vue"
import ImpactFundPartners from "../components/ImpactFund/ImpactFundPartners.vue"
import ImpactFundInterested from "../components/ImpactFund/ImpactFundInterested.vue"
import ImpactFundStatistics from "../components/ImpactFund/ImpactFundStatistics.vue"

export default {
  name: "ImpactFund",
  components: {
    ImpactFundHero,
    Footer,
    ImpactFundWhatItIs,
    ImpactFundMoneyFlow,
    ImpactFundWeAreHereFor,
    ImpactFundPartners,
    ImpactFundInterested,
    ImpactFundStatistics,
  },
  data() {
    return {}
  },
}
</script>
