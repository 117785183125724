<template>
<div class='pt-8 max-w-4xl m-auto'>
  <div class='relative'>
    <ImageImpactFundTitle class='absolute inset-0 w-2/3 m-auto' />
    <img src="../../assets/images/the-impact-fund-cloud.png" />
    </div>
    <div class='relative mt-8'>
      <div class='absolute -bottom-10 w-32 h-32'>
        <p class='absolute inset-0 flex items-center justify-center flex-col text-white text-center font-heading text-3xl'>{{totalETH}} Ξ<span class='font-text text-base'>raised</span></p>
        <ImageBadge class='' />
      </div>
    <img class='w-full' src="../../assets/images/map.png"/>
    </div>
    <div class='relative mt-16 lg:mt-20 text-center'>
      <a href="https://etherscan.io/address/0x74360cC0ADA344CBa195Bd202afFEa5d9a52651f" target="_blank" class='text-cdlpink hover:text-cdlpink-dark ease-in-out duration-300 transition-all font-heading text-xl md:text-2xl lg:text-4xl text-center break-all'>0x74360cC0ADA344CBa195Bd202afFEa5d9a52651f</a>
      <p class='text-center text-cdlpink-light text-sm lg:text-lg'>ETH wallet address</p>
      <p class='pt-4 text-gray-400 text-center text-xs max-w-2xl m-auto'>Total Ξ raised differs from summary of all funds received to Impact Fund wallet, because we've made donations even before creating Impact Fund wallet. You may find these transactions in <router-link class='underline' to="/opendata">Open Data</router-link></p>
    </div>
  <!-- <BaseHeadingAndText :heading="heading" :text="text" headingSize="6xl" textSize="xl" headingColor="cdlpink" /> -->
  </div>
</template>

<script>
import mixinGlobal from "@/mixins/mixinGlobal"
import ImageImpactFundTitle from '@/assets/images/the-impact-fund-title.svg?inline'
import ImageBadge from '@/assets/images/badge.svg?inline'

export default {
  name: "ImpactFundHero",
  mixins: [mixinGlobal],
  components: {
    ImageImpactFundTitle,
    ImageBadge
  },
  data() {
    return {
      // heading: "Impact Fund",
      // text: "Our goal is to induce maximum positive impact on the world especially for animals & medicine. Contributions are not purely financial - we frequently work with the carefully-selected organizations we support to help them do good. The fund was born out of a desire to drive targeted change rather than just giving away money with no accountability. On top of that, we implemented a model for long-term sustainability so the vision doesn't die once capital runs out.",
    }
  },
  async created() {
    await Promise.all([
      this.$store.dispatch("partners/bind"),
      this.$store.dispatch("donations/bind"),
      this.$store.dispatch("exchangeRates/bind")
    ])
  },
  computed: {
    partners() {
      return this.$store.state.partners.partners
    },
    totalETH() {
      return this.roundAmount(
        this.partners.reduce(function (a, b) {
          console.log(a,b)
          if (b.demo) return a
          else return a + b.totalDonations.ETH
        }, 0), 1
      )
    },
  }
}
</script>
