<template>
  <div>
    <BaseHeadingAndText heading="Partners">
      <div>
        <BaseLoading v-if="!loadedPartners" :center="false" size="s" />
        <div v-if="loadedPartners">
          <div v-for="(partner, pIndex) in partners" :key="pIndex">
            -
            <a class="underline" target="_blank" :href="partner.websiteURL">{{
              partner.name
            }}</a>
          </div>
        </div>
      </div>
    </BaseHeadingAndText>

    <BaseHeadingAndText heading="">
      Apply for funding if you're a shelter, vet, biotech or someone that fits
      the mold! Tell us your situation or how the funds will drive impact!
    </BaseHeadingAndText>
    <BaseButton
      event="impact_fund_partners_apply_for_funding_click"
      class="mt-4"
      :url="applyURL"
      >Apply for funding</BaseButton
    >

    <BaseHeadingAndText heading="Partner widget" class="mt-16">
      Our partners can put a donation tracking widget on their website to make
      receiving ETH donations easy.
    </BaseHeadingAndText>
    <BaseButton
      event="impact_fund_partners_apply_for_funding_click"
      class="mt-4"
      page="widget"
      >Get your widget</BaseButton
    >
  </div>
</template>

<script>

export default {
  name: "Intro",
  components: {
  },
  data() {
    return {
      applyURL: "mailto:impact@cuddle.me",
    }
  },
  computed: {
    partners() {
      return this.$store.state.partners.partners
    },
    loadedPartners() {
      return this.$store.state.partners.loadingState.partners == "loaded"
    },
  },
  async created() {
    await this.$store.dispatch("exchangeRates/bind")
    await this.$store.dispatch("partners/bind")
  },
  async destroyed() {
    await Promise.all([
      this.$store.dispatch("exchangeRates/unbind"),
      this.$store.dispatch("partners/unbind"),
    ])
  },
  methods: {
    onShowMore() {
      console.log("show more")
      this.showMore = true
    },
  },
}
</script>
