<template>
  <div class="">
    <BaseHeadingAndText heading="Statistics">
      <span class='text-xl font-bold'>50% of dogs over 10 years old develop cancer</span><br>[<a
        class="underline" target="_blank"
        href="https://pets.webmd.com/dogs/guide/dogs-and-cancer-get-the-facts#1"
        >source</a
      >]<br /><br />

      <span class='text-xl font-bold'>Over 98% of pets in the US are uninsured</span><br>[<a
        class="underline" target="_blank"
        href="https://www.iii.org/fact-statistic/facts-statistics-pet-ownership-and-insurance"
        >source</a
      >]<br /><br />

      <span class='text-xl font-bold'>It's estimated that 56% of dogs & 71% of cats entering shelters in the US
      will be euthanized</span><br>[<a
        class="underline" target="_blank"
        href="https://www.americanhumane.org/fact-sheet/animal-shelter-euthanasia-2/"
        >source</a
      >]<br /><br />

      These are all causes we can help...
    </BaseHeadingAndText>
  </div>
</template>

<script>

export default {
  name: "ImpactFundApply",
  components: {
    
  },
  data() {
    return {
      showMore: false,
    }
  },
  methods: {
    onShowMore() {
      console.log("show more")
      this.showMore = true
    },
  },
}
</script>
